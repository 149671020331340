import React, { useEffect, useState } from 'react'
import { sleep, UseTokenInfo, checkIsApproved, approveContract, getUserBalance } from "../hooks/UseContract"
import { getRewardTokenInfo, getUser, getUserReward, Claim, getUSDTprice, getPoolLength, getPoolInfoLimit, FARMS, plan } from "../hooks/UseStake"
import { ONEDAYINSECONDS } from '../config/env';
import { useNavigate } from "react-router-dom";
import { getAccount } from "../hooks/useAccount"
import ClaimModal from "./ClaimModal";
import PlaceholdingLoader from './PlaceholdingLoader'
import { rewardTokenDetails } from '../config/Farms'
const LockedStakingTable = (props) => {
  console.log("LockedStakingTable", props?.pid);
  var navigate = useNavigate()

  const [pooldays, setpoolDays] = useState('')
  const [tokenInfo, setTokenInfo] = useState();
  const [pending, setPending] = useState(0)
  const [accountInfo, setAccountInfo] = useState("");
  const [setloader, showSetloader] = useState(false);
  const [userStakedAmount, setUserStakedAmount] = useState(0);
  const [umudiPerBlock, setUmudiPerBlock] = useState();
  const [userstatedate, setuserstatedate] = useState(0)
  const [claim, setShowClaim] = useState(false)
  const [contentLoading, setContentLoading] = useState(false);
  const [rewardToken, setRewardToken] = useState();
  const getData = async () => {
    await getTokenInfo();
    if (props.type == "lockedStaking") {
      const poolDetails = await plan(props?.pid == 7 ? 4 : props?.pid);
      // console.log("POOLDETAILS",poolDetails);
      setUmudiPerBlock(poolDetails.UmudiPerBlock)
      var poolday = ((parseInt(poolDetails?.lockedPeriod) / ONEDAYINSECONDS))
      setpoolDays(poolday.toFixed());
      setContentLoading(false)
    }
    props.hideloader()
  }

  const getTokenInfo = async () => {
    var TokenInfo = await UseTokenInfo(props?.datas?.lpToken)
    setTokenInfo(TokenInfo);
    // console.log("Reward TokenInfo", TokenInfo)
    var RewardTokenInfo = await getRewardTokenInfo(props?.datas?.rewardToken);
    console.log("props.pid_RewardTokenInfo", RewardTokenInfo)
    var getuser = await getUser(props?.pid, props?.accountInfo);
    console.log("getuser", getuser)
    if (props.type == "reward") {
      const poolDetails = await plan(getuser?.PlanId);
      setUmudiPerBlock(poolDetails?.UmudiPerBlock)
      console.log("POOLDETAILS", poolDetails);
      var poolday = ((parseInt(poolDetails?.lockedPeriod) / ONEDAYINSECONDS))
      setpoolDays(poolday.toFixed());
    }
    // const RewardToken = rewardTokenDetails[props?.pid];
    // console.log("RewardToken.token",RewardToken.token);
    const RewardToken = RewardTokenInfo?.symbol;
    setRewardToken(RewardToken)
    setUserStakedAmount(getuser?.amount);
    const datee = new Date(parseInt(getuser?.userLockedPeriod) * 1000);
    const unstakeDate = datee.toLocaleDateString();
    if (getuser?.userLockedPeriod > 0)
      setuserstatedate(unstakeDate)
    var UserReward = await getUserReward(props.pid, getAccount())
    var pending = (parseInt(UserReward || 0) + parseInt(getuser?.pendingRewards || 0));
    console.log("pending1", pending);
    var usdtprice = await getUSDTprice(pending, props?.datas?.lpToken, props?.datas?.rewardToken);
    console.log("usdtprice", usdtprice / 10 ** RewardTokenInfo?.decimals);
    setPending((usdtprice / 10 ** RewardTokenInfo?.decimals).toFixed(20) ? (usdtprice / 10 ** RewardTokenInfo?.decimals).toFixed(20) : "0")
  }

  useEffect(() => {
    setAccountInfo(getAccount())
    getData();
    // setContentLoading(true)
  }, [props])

  const unStake = async (pid) => {
    // console.log("IN")
    const getuser = await getUser(pid, getAccount());
    setUserStakedAmount(getuser.amount);
    const datee = new Date(parseInt(getuser?.userLockedPeriod) * 1000);
    const unstakeDate = datee.toLocaleDateString();
    if (getuser?.userLockedPeriod > 0)
      setuserstatedate(unstakeDate)
    const sendData = { tokenInfo: tokenInfo, userstatedate: userstatedate, pid: pid, userStakedAmount: userStakedAmount }
    navigate(`/withdraw`, { state: sendData })
  }

  const claimRewards = async (pid) => {
    // console.log("USERCLAIM",pid);
    const userclaim = await Claim(getAccount(), pid);
    // console.log("USERCLAIM", userclaim,pid);
  }
  return (
    <>
      {
        // contentLoading ?   <PlaceholdingLoader classnames="stripe small-stripe mb-2"/> :
        props?.type == "lockedStaking" ?
          <tbody>
            <tr>
              <th scope="row">{props.pid + 1}</th>
              {/* <td>{Math.floor(parseInt(props.datas.lockedPeriod * 1000) / (1000 * 60 * 60 * 24))}<span className='valueplan'> Days</span></td> */}
              <td>{pooldays}<span className='valueplan'> Days</span></td>
              {/* <td> {((props.datas.UmudiPerBlock / 10 ** tokenInfo?.decimals) * Number(pooldays)).toFixed(2)} <span className='valueplan'>%</span></td> */}
              <td> {((umudiPerBlock / 10 ** 18) * Number(pooldays)).toFixed(2)} <span className='valueplan'>%</span></td>
              <td>{(props.pid == 5 || props.pid == 6) ? props?.datas?.depositFeeBP / 200 : props?.datas?.depositFeeBP / 100} %</td>
              {console.log("props?.datas?.depositFeeBP", props?.datas?.depositFeeBP)}

            </tr>
          </tbody>

          :

          <tbody>
            <tr>
              {userStakedAmount && userStakedAmount > 0 ?
                <>
                  {/* <th scope="row">{props.pid + 1}</th> */}
                  <td>{pooldays}<span className='valueplan'> Days</span></td>
                  <td> {((umudiPerBlock / 10 ** 18) * Number(pooldays)).toFixed(2)} <span className='valueplan'>%</span></td>
                  <td>{userStakedAmount && (parseInt(userStakedAmount) / 10 ** tokenInfo?.decimals).toFixed(10)}</td>
                  <td>{parseFloat(pending) > 0 ? pending : "0.00000000000000000000"}</td>
                  {/* <td>{pending}</td> */}

                  {console.log("pending>>>>>>>", pending)}
                  <td>{tokenInfo?.symbol}</td>
                  <td>{rewardToken}</td>
                  <td>{userstatedate}</td>
                  <td>
                    <button className="btn_yellow me-3" onClick={() => { claimRewards(props?.pid) }} disabled={parseFloat(pending) <= 0}>Claim</button>
                    <button className="btn_yellow" onClick={() => { unStake(props?.pid) }}>Withdraw</button>
                    {props.showRecord(true)}
                  </td>

                </>
                :
                <></>

              }
            </tr>
          </tbody>
      }

      {/* <td>{Math.floor(parseInt(props.datas.lockedPeriod * 1000) / (1000 * 60 * 60 * 24))}<span className='valueplan'> Days</span></td> */}
      {/* <td> {((props.datas.UmudiPerBlock / 10 ** tokenInfo?.decimals) * Number(pooldays)).toFixed(2)} <span className='valueplan'>%</span></td> */}
      {claim && <ClaimModal onDismiss={() => setShowClaim(false)} />}
    </>
  )
}

export default LockedStakingTable
