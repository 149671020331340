import React, { useEffect, useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';

import eth from '../images/eth.png'
import bnb from '../images/bnb.png'
import busd from '../images/busd.png'
import { OnStake } from '../hooks/UseStake';
import { getAccount } from '../hooks/useAccount';
import { checkIsApproved, UseapproveContract, getUserBalance } from '../hooks/UseContract'
import { rewardTokenDetails, irenaLpToken, stakeTokens } from '../config/Farms';
import { getPairPid } from '../hooks/UseStake';
import toast, { Toaster } from 'react-hot-toast';

const StakingTwo = (props) => {
  console.log(props, "StakingTwo...")
  const piddata = props.pid
  console.log("piddata_piddata", piddata);
  // console.log("lpTOken",props?.selectPool?.lpToken);
  const [selectRewardToken, setSelectRewardToken] = useState();
  console.log("selectRewardToken_selectRewardToken_", selectRewardToken);
  const [Maxdeposit, setmaxDeposit] = useState("0");
  const [accountInfo, setAccountInfo] = useState("");
  const [isMax, setIsMax] = useState(false);
  const [deposit, setDeposit] = useState("0");
  const [pendingTx, setpendingTx] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [selectPairPid, setSelectPairPid] = useState();
  const [depositTokenBalance, setDepositTokenBalance] = useState();
  const [selectStakeToken, setSelectStakeToken] = useState();
  console.log("selectStakeToken_selectStakeToken_", selectStakeToken);
  const [rewardListToken, setRewardListToken] = useState(rewardTokenDetails);
  console.log("rewardListToken___rewardListToken", rewardListToken);

  useEffect(() => {
    setAccountInfo(getAccount());
    checkIsApproval()
    setAccountInfo(getAccount());
    setmaxDeposit(props.amount);
    // setRewardTokens(FARMS?.rewardTokenDetails)
  }, [props]);

  useEffect(() => {
    getUserStakeTokenBalance()
    checkIsApproval()
  }, [selectStakeToken]);

  const getUserStakeTokenBalance = async () => {
    const tokenBalance = getAccount() ? await getUserBalance(selectStakeToken?.address, getAccount()) : ""
    console.log("tokenBalance", tokenBalance);
    // setDepositTokenBalance(parseInt(tokenBalance).toFixed(2))
    setDepositTokenBalance(tokenBalance)
  }

  const checkIsApproval = async () => {
    console.log("APPROVAL...selectStakeToken ", selectStakeToken)
    const approval = await checkIsApproved(getAccount(), selectStakeToken?.address);
    console.log("APPROVAL...", approval)
    setIsApproved(approval);
  }

  const maxDeposit = () => {
    // console.log("userTokenBalance", props.userTokenBalance)
    if (depositTokenBalance >= 0) {
      setIsMax(true);
      setmaxDeposit(depositTokenBalance);
    }
  }

  const approve = async () => {
    if (accountInfo) {
      await UseapproveContract(accountInfo, selectStakeToken?.address);
      setIsApproved(true);
    }
    else {
      // toast.error("Connect Your Wallet First!", {
      //   style: {
      //     minWidth: '300px',
      //     minHeight: '55px'
      //   }
      // })
      alert("SORRY NOT FOUND");
    }
  }

  const onchange = async (eventKey) => {
    console.log("props?.data?.address", props?.data?.address, "----", "selectRewardToken.address", JSON.parse(eventKey).address);
    const getPid = await getPairPid(selectStakeToken?.address, JSON.parse(eventKey).address);
    console.log("GET PID", getPid);
    setSelectPairPid(getPid)

  }
  const checkStakeTokenBalance = async () => {
    const check = depositTokenBalance ? props.sendDataToParent(4, props.data, props.userTokenBalance, props.pid, false, props.selectPool, isMax ? Maxdeposit : deposit, selectRewardToken, selectPairPid, props?.poolLockedDays, selectStakeToken) :
      toast.error("Your Balance is Insufficient", {
        duration: 2000,
        style: {
          minWidth: '300px',
          minHeight: '55px'
        }
      })
  }

  const selectStakeTokens = async (eventKey) => {


    let selectValue = JSON.parse(eventKey);
    console.log("selectValue.token ", selectValue);
    if (selectValue.token == "BUSD") {
      setRewardListToken(rewardTokenDetails)
      setSelectRewardToken()
    }
    if (selectValue.token == "PAYU") {
      const found = rewardTokenDetails.find(element => element.token == "BITCOIN");
      setRewardListToken([found])
      setSelectRewardToken()
    }
    else if (selectValue.token == "BNB") {
      const found = rewardTokenDetails.find(element => element.token == "BNB");
      setRewardListToken([found])
      setSelectRewardToken()
    }
    else if (selectValue.token == "USDT") {
      const found = rewardTokenDetails.find(element => element.token == "USDT");
      setRewardListToken([found])
      setSelectRewardToken()
    }
    else if (selectValue.token == "CAKE") {
      const found = rewardTokenDetails.find(element => element.token == "CAKE");
      setRewardListToken([found])
      setSelectRewardToken()
    }
    else if (selectValue.token == "DOGE") {
      const found = rewardTokenDetails.find(element => element.token == "DOGE");
      setRewardListToken([found])
      setSelectRewardToken()
    }

  }


  return (
    <div className="card shadowstyle">
      {console.log("selectStakeToken", selectStakeToken)}
      {/* {console.log("deposit", deposit, "------", "Maxdeposit", Maxdeposit, "---", "rewardTokens", selectRewardToken)} */}
      <div className="card-body">
        <p className="card-title mb-3 flex_bet_text"><span>Invest {selectStakeToken ? selectStakeToken.token : ""} and Reward {selectRewardToken ? selectRewardToken.token : ""}</span>
          <span className="card-title-sm">
            {console.log("depositTokenBalance", depositTokenBalance)}
            {depositTokenBalance >= 0 ? `Balance : ${depositTokenBalance == 'NaN' ? 0 : depositTokenBalance} ${selectStakeToken?.token == undefined ? "" : selectStakeToken?.token}` : ""}
          </span></p>

        <div className='row'>

          <div className='col-md-6'>
            <div className="input-group mb-3 input_grp_nowrap">
              <input type="text" className="form-control" placeholder="Stake Token" aria-label="Stake Token" aria-describedby="basic-addon2" value={selectStakeToken ? selectStakeToken.token : ""} />
              <div className="input-group-append">
                <span className="input-group-text" id="basic-addon2">
                  <Dropdown onSelect={(eventKey) => { setSelectStakeToken(JSON.parse(eventKey)); selectStakeTokens(eventKey) }}>

                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <img src={eth} className="img-fluid imgblk" />{selectStakeToken ? selectStakeToken.token : "Stake Token"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {stakeTokens && stakeTokens?.map((value, index) => {
                        if (piddata == "2" || piddata == "3" || piddata == "5" || piddata == "6") {
                          console.log("value_token_data", value?.token);
                          return (
                            <Dropdown.Item href="javascript:void(0)" eventKey={JSON.stringify(value)} disabled={(piddata == "2" || piddata == "3") ?
                              ((value?.token == "PAYU") ? false : true) : (piddata == "5" || piddata == "6") ? ((value?.token == "PAYU") ? true : false) : false}>
                              <img src={eth} className="img-fluid imgwht" />
                              {value?.token}
                            </Dropdown.Item>)

                        }
                      }

                      )}
                    </Dropdown.Menu>
                  </Dropdown></span>
              </div>
            </div>
          </div>


          {/* Reward TOKENS */}

          <div className='col-md-6'>
            <div className="input-group mb-3 input_grp_nowrap">
              <input type="text" className="form-control" placeholder="Reward Token" aria-label="Reward Token" aria-describedby="basic-addon2" value={selectRewardToken ? selectRewardToken.token : ""} />
              <div className="input-group-append">
                <span className="input-group-text" id="basic-addon2">
                  <Dropdown onSelect={(eventKey) => { setSelectRewardToken(JSON.parse(eventKey)); onchange(eventKey) }}>


                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <img src={eth} className="img-fluid imgblk" />{selectRewardToken ? selectRewardToken.token : "Reward Token"}
                    </Dropdown.Toggle>
                    {/* 
                    <Dropdown.Menu>
                      {rewardListToken && rewardListToken?.map((value, index) => {

                        if (selectStakeToken?.token == "PAYU" && piddata == "4") {
                          if (value?.token === "USDT") {
                            return ((<Dropdown.Item href="javascript:void(0)" eventKey={JSON.stringify(value)}><img src={eth} className="img-fluid imgwht" />{value?.token}</Dropdown.Item>))
                          } else if (value?.token === "USDT") {
                            return ((<Dropdown.Item href="javascript:void(0)" eventKey={JSON.stringify(value)}><img src={eth} className="img-fluid imgwht" />{value?.token}</Dropdown.Item>))
                          }
                        }

                        else {
                          return ((<Dropdown.Item href="javascript:void(0)" eventKey={JSON.stringify(value)}><img src={eth} className="img-fluid imgwht" />{value?.token}</Dropdown.Item>))
                        }
                      }
                      )}
                    </Dropdown.Menu> */}

                    <Dropdown.Menu>
                      {rewardListToken && rewardListToken?.map((value, index) =>
                        (<Dropdown.Item href="javascript:void(0)" eventKey={JSON.stringify(value)}><img src={eth} className="img-fluid imgwht" />{value?.token}</Dropdown.Item>)
                      )}
                    </Dropdown.Menu>
                  </Dropdown></span>
              </div>
            </div>
          </div>

          <div className='col-md-6 mb-2'>
            <div className="input-group">
              <input disabled={depositTokenBalance > 0 ? false : true} min="0"
                type="number" className="form-control" placeholder="Investment Amount" aria-label="Investment Amount" aria-describedby="basic-addon2"
                value={isMax ? Maxdeposit : deposit}
                onChange={(e) => { setDeposit(e.target.value); setIsMax(false); }} />
              <div className="input-group-append">
                <span className="input-group-text" id="basic-addon2"><button disabled={depositTokenBalance > 0 ? false : true} className='input_btn' onClick={() => { setIsMax(true); maxDeposit() }}>Max</button></span>
              </div>
            </div>
            {/* <small className='descinput'> The amount can not be lower the 2000 PAYU</small> */}
          </div>



          {/* Stake TOKENS */}




          <p className='descline mt-2'>* You are not allowed to degrade the plan,you are only able to upgrade the plan.</p>
        </div>

        <div className='stakingbtnsec mt-4'>
          {/* <button className="btn_yellow wallet_btn mx-3 mb-3 mb-md-0" onClick={() => { props.sendDataToParent(1, props.data, props.userTokenBalance, props.pid, false, props.selectPool, isMax ? Maxdeposit : deposit, selectRewardToken, selectPairPid, props?.poolLockedDays);setSelectRewardToken('');setSelectStakeToken(''); }}>Back</button> */}

          <button className="btn_yellow wallet_btn mx-3 mb-3 mb-md-0" onClick={() => { props.sendDataToParent(1, props.data, props.userTokenBalance, props.pid, false, props.selectPool, isMax ? Maxdeposit : deposit, selectRewardToken, selectPairPid, props?.poolLockedDays) }}>Back</button>
          {/* {console.log("isApproved",isApproved)} */}
          {(isApproved) ?
            <button disabled={selectStakeToken && selectRewardToken && (deposit > 0 || isMax) ? false : true} className="btn_yellow mb-3 mb-md-0" onClick={async () => { checkStakeTokenBalance() }}>Next</button>
            :
            <button className="btn_yellow  mb-3 mb-md-0" onClick={() => approve()}>Approve</button>
          }

        </div>




      </div>
    </div >

  )
}

export default StakingTwo
