import React, { useEffect, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Header from './Header'
import Dropdown from 'react-bootstrap/Dropdown';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Link, useLocation } from "react-router-dom";
import { ONEDAYINSECONDS } from '../config/env';
import { sleep, UseTokenInfo, checkIsApproved, approveContract, getUserBalance } from "../hooks/UseContract"
import { getRewardTokenInfo, getUser, getUserReward, Claim, getUSDTprice, getPoolLength, getPoolInfoLimit, FARMS } from "../hooks/UseStake"
import StakingOne from './StakingOne'
import StakingTwo from './StakingTwo'
import StakingThree from './StakingThree'
import StakingFour from './StakingFour'
import StakingFive from './StakingFive'
import leaf from '../images/leaf.png'
import StakingAccordion from './StakingAccordion';




const Staking = (props) => {
  let location = useLocation();
  // console.log(location.state.from);
  const [step, setStep] = useState(1);
  const [tokenInfo, setTokenInfo] = useState();
  const [userTokenBalance, setuserTokenBalance] = useState("");
  const [pid, setPid] = useState();
  const [poolLength, setPoolLength] = useState(0);
  const [poolLimit, setPoolLimit] = useState([]);
  console.log("poolLimit_poolLimit_V_poolLimit", poolLimit);
  const [lpsymbol, setlpsymbol] = useState({})
  const [accountInfo, setAccountInfo] = useState("");
  const [hidden, setHidden] = useState(true);
  const [selectPool, setSelectPool] = useState();
  const [amount, setAmount] = useState();
  const [selectRewardToken, setSelectRewardToken] = useState();
  const [selectPairPid, setSelectPairPid] = useState();
  const [poolLockedDays, setPoolLockedDays] = useState();
  const [selectStakeToken, setSelectStakeToken] = useState();

  const sendDataToParent = (index, tokenInfo, userTokenBalance, pid, hidden, selectedPool, stakeAmount, selectRewardTokenDetails, selectedPairPid, poolLockedDays, stakeToken) => { // the callback. Use a better name
    // console.log(index, tokenInfo, userTokenBalance, pid,hidden,selectedPool,stakeAmount,selectRewardTokenDetails,selectedPairPid, poolLockedDays, "SECOUND PAGE DETAILS...");
    // console.log("stakeToken???",stakeToken);
    setTokenInfo(tokenInfo);
    setuserTokenBalance(userTokenBalance);
    setPid(pid);
    setHidden(hidden);
    setStep(index);
    setSelectPool(selectedPool);
    setAmount(stakeAmount);
    setSelectRewardToken(selectRewardTokenDetails);
    setSelectPairPid(selectedPairPid);
    setPoolLockedDays(poolLockedDays);
    setSelectStakeToken(stakeToken)
  };

  const getData = async () => {
    var data = await getPoolLength();
    console.log("data_data_datacx", data);
    if (data) setPoolLength(data);
    var pool = await getPoolInfoLimit(0, data)
    setlpsymbol(FARMS)
    if (pool) {
      console.log(pool, 'pool_pool')
      setPoolLimit(pool);
      hideloader()
    }
  }

  useEffect(() => {
    setAccountInfo(location.state.from)
    getData();
    showloader();
  }, [accountInfo])

  const showloader = async () => {
    document.getElementById("loader_div").style.display = "block";
  }

  const hideloader = async () => {
    document.getElementById("loader_div").style.display = "none";
  }

  return (
    <section>
      <div className='showloader minwidth-534 text-center' id="loader_div">
        <div className='loader ' >
          <img src={leaf} className="spin" />
          {/* <img src={leaf} className="leaf" /> */}
        </div>
      </div>
      <div className='innerpages stakingpage'>
        <Header />
        <div className='container container_custom pt-main-top pb-4'>
          <div className='headsec mb-3'>
            <div>
              <h3 className='headtitle'>Staking</h3>
            </div>
            <div>
              <Link to="/calculate" className='headright'>PAYU Calculator</Link>
            </div>
          </div>
          <div className='cardsec'>


            <div className={step == 1 ? "step_count firststep mb-4 active" : "step_count firststep mb-4"}>

              <section>
                <div className='showloader minwidth-534 text-center' id="loader_div">
                  <div className='loader ' >
                    <img src={leaf} className="spin" />
                    {/* <img src={leaf} className="leaf" /> */}
                  </div>
                </div>
                {/* {console.log("userTokenBalance...",userTokenBalance)} */}
                <div className="card shadowstyle">
                  <div className="card-body">
                    <p className="card-title mb-3">Duration</p>
                    <p className='descline mt-2'>* You are not allowed to degrade the plan,you are only able to upgrade the plan.</p>

                    <div className='row accordion_row_height'>
                      <Accordion>
                        {
                          poolLimit && poolLimit.map((data, index) =>
                          //  ( index == 1 || index == 2  ||  index == 3 ? //hide 90 days pool
                          (
                            index == 2 || index == 3 ||

                              index == 5 || index == 6 ?


                              <StakingAccordion sendDataToParent={sendDataToParent} datas={data} pid={index} lpsymbols={lpsymbol[index]} accountInfo={accountInfo} />
                              : <></>
                          )
                          )}
                      </Accordion>
                    </div>
                    {sessionStorage.getItem("accountInfo") == null || undefined || "" ?
                      <div className='stakingbtnsec mt-4'>
                        <button disabled={hidden} className="btn_yellow">Connect Wallet</button>
                      </div> :
                      <>
                        <div className='stakingbtnsec mt-4'>
                          <button disabled={hidden} className="btn_yellow" onClick={() => step != undefined ? setStep(2) : ""}>Next</button>
                        </div>
                      </>
                    }



                  </div>
                </div>
              </section>

              {/* <StakingOne sendDataToParent={sendDataToParent} data={location?.state?.from} /> */}

            </div>
            <div className={step == 2 ? "step_count secondstep mb-4 active" : "step_count secondstep mb-4"}>
              <StakingTwo sendDataToParent={sendDataToParent} data={tokenInfo} userTokenBalance={userTokenBalance} pid={pid} selectPool={selectPool} poolLockedDays={poolLockedDays} amount={amount} />

            </div>
            {/* <div className={step == 3 ? "step_count thirdstep mb-4 active" : "step_count thirdstep mb-4"}>
              <StakingThree sendDataToParent={sendDataToParent} />
            </div> */}

            <div className={step == 4 ? "step_count fourthstep mb-4 active" : "step_count fourthstep mb-4"}>
              <StakingFour sendDataToParent={sendDataToParent} data={tokenInfo} userTokenBalance={userTokenBalance} pid={pid} selectPool={selectPool} amount={amount} rewardToken={selectRewardToken} selectPairPid={selectPairPid} poolLockedDays={poolLockedDays} selectStakeToken={selectStakeToken} />
            </div>


            <div className={step == 5 ? "step_count fifthstep mb-4 active" : "step_count fifthstep mb-4"}>
              <StakingFive sendDataToParent={sendDataToParent} />
            </div>

          </div>
        </div>


      </div>

    </section>





  )
}

export default Staking
