import React, { useEffect, useState } from 'react'
import CopyToClipboard from "react-copy-to-clipboard";
import toast, { Toaster } from 'react-hot-toast';


const StakingFive = () => {
  //  const [hash, setHash] = useState()
  useEffect(() => {
    // setHash(JSON.parse(localStorage.getItem('HASH'))) 
    // console.log("HASH",JSON.parse(localStorage.getItem('HASH')))
    const timer = setTimeout(() => {

      document.querySelector("#circle_loader").classList.toggle('load-complete');
      document.querySelector("#checkmark").classList.toggle('checkmark');

      // $('.circle-loader').toggleClass('load-complete');
      // $('.checkmark').toggle();
    }, 1000);
    return () => clearTimeout(timer);

    // Update the document title using the browser API
  }, []);
  const copyText = async (a, b) => {
    // toast.success("Address Copied", {
    //   position: position.position,
    //   style: style,
    //   iconTheme: iconTheme,
    // }
    toast.success("Address Copied", {
      duration: 2000,
      style: {
        minWidth: '300px',
        minHeight: '55px'
      }
    })
    // )

  }
  return (
    <div className='row'>
      <div className='col-12 col-sm-9 col-md-7 col-lg-6 col-xl-4 col-xl-custom mx-auto'>
        <div className="card shadowstyle px-3 pt-0 pb-4">
          <div className="card-body">

            <div className='text-center mt-5'>
              <div className="circle-loader" id="circle_loader">
                <div className="draw" id="checkmark"></div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 mb-3'>
                <p className='white_text_md text-center'>Stake Successfully</p>

                <p className='white_text_sm text-center'>{JSON.parse(localStorage.getItem('HASH'))}</p>


              </div>
            </div>

          </div>



          <div className='stakingbtnsec mt-0 pb-2'>
            {/* <button className="btn_yellow mx-3">Copy</button> */}
            {/* https://bscscan.com/tx/ */}
            <CopyToClipboard text={`https://bscscan.com/tx/${JSON.parse(localStorage.getItem('HASH'))}`} onCopy={() => { copyText('invite link', `https://bscscan.com/tx/${JSON.parse(localStorage.getItem('HASH'))}`) }}>
              <button variant='link' className="btn_yellow mx-3"> Copy</button>
            </CopyToClipboard>

          </div>




        </div>
      </div>
    </div>

  )
}

export default StakingFive
